import logo from './logo.svg';
import React, {useState} from 'react';
import axios from 'axios';
import './index.css';
function App() {
  const [data,setData] = useState({})
  const [location, setLocation] = useState('')


  const dataUrl = `https://api.openweathermap.org/data/2.5/weather?q=${location}&units=imperial&appid=e71dd4485d3f63eb4797842ba9d50467`

  const searchLocation = (event) =>{
    if(event.key === 'Enter'){
    axios.get(dataUrl).then((response)=>{
      setData(response.data)
      console.log(response.data)
    })
    setLocation('')
  }
  }


  return (
    <div className="App">
      <div className="app__search">
      <input 
      className="app__input"
      value={location}
      onChange={event => setLocation(event.target.value )}
      placeholder="Enter City for Weather"
      onKeyPress={searchLocation}
      type="text"
      />
      </div>
      <div className="app__container">
       
      <div className="app__top">
        <div className="app__location">
         <p> {data.name} </p>
        </div>

        <div className="app__temp">
         {data.main ? <h1>{data.main.temp.toFixed()} F° </h1> : null} 
        </div>
        <div className="app__description">
        {data.weather ? <p>{data.weather[0].main}  </p> : null} 
        </div>
       </div>


    {data.name != undefined && 
        <div className="app__bottom">

        <div className="app__feels ">
          {data.main ? <p className="bold">{data.main.feels_like.toFixed()} F° </p> : null} 
          <p> Feels Like </p>
          </div>

          <div className="app__humidity">
          {data.main ? <p className="bold">{data.main.humidity} % </p> : null} 

          <p> Humidity </p>
          </div>
          <div className="app__wind">
          {data.wind ? <p className="bold">{data.wind.speed.toFixed()} MPH </p> : null} 

        <p> Wind Speed </p>
          </div>
      </div>
        
    }
       

      
    
     </div>

    </div>
  );
}

export default App;
